import React from 'react';
import Layout from '../components/layout';
import * as indexCss from './modules/index.module.scss';
import * as textCss from './modules/text.module.scss';

import {graphql, useStaticQuery} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const ExpositionEncoursPage = () => {
    const datas = useStaticQuery(graphql`
        query {
          allContentfulExpositionsEnCours {
            edges {
              node {
                titre
                sousTitre
                imageMiseEnAvant {
                  resize {src}
                  file {url}
                  title
                }
                contenuDeLaPage {raw}
                quelquesOeuvres
                autresImages {
                  resize {src}
                  file {url}
                  title
                  description
                }
              }
            }
          }
        }`
    );
    const data = datas.allContentfulExpositionsEnCours.edges[0].node;
    const content = JSON.parse(data.contenuDeLaPage.raw);

    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <h2>{data.sousTitre}</h2>
                <a href={data.imageMiseEnAvant.file.url} target="_blank" rel="noreferrer"><img className={textCss.img} src={data.imageMiseEnAvant.resize.src} alt={data.imageMiseEnAvant.title}/></a>
                <div>{documentToReactComponents(content)}</div>

                <h3 style={{marginTop: 50}}>{data.quelquesOeuvres}</h3>
                <div className={indexCss.collection}>
                    {data.autresImages.map(image => {
                        return (
                            <div className={indexCss.imageContainer}>
                                <a href={image.file.url} target="_blank" rel="noreferrer">
                                  <img src={image.resize.src} alt={image.title} className={indexCss.image + ' ' + textCss.expoimg}/>
                                </a>
                                <p className={indexCss.caption}>{image.description}</p>
                            </div>
                        )
                    })}
                </div>

            </div>
        </Layout>
    )
}

export default ExpositionEncoursPage;
